<template>
  <div class="box">
    <div class="title-box">
      <a href="/"
        ><font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" /> 홈</a
      >
    </div>

    <div class="title">
      <h2>
        데이터 연결하기
      </h2>
      <p>여러 플랫폼의 내 데이터를 한번에 관리하세요</p>
    </div>

    <div v-if="webId != null">
      <div>
        <ul class="category">
          <li class="sns">
            <h3>
              <font-awesome-icon icon="fa-solid fa-comments" size="1x" /><span
                >SNS</span
              >
            </h3>
            <ul class="apps">
              <li @click="open_inbox('kakao')">
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/kakaotalk_app_logo.png"
                    alt="카카오톡"/></span
                ><span>카카오톡</span
                ><span class="connectStatus on" v-if="kakaoUserId != null"
                  >연결중</span
                >
                <font-awesome-icon
                  v-if="kakaoUserId != null"
                  icon="fa-solid fa-chevron-right"
                  size="1x"
                  class="on"
                />
              </li>
              <!-- 모달 불러오기 -->
              <li @click="open_inbox('line')">
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Line_app_logo.png"
                    alt="라인"/></span
                ><span>라인</span
                ><span class="connectStatus on" v-if="lineUserId != null"
                  >연결중</span
                >
                <font-awesome-icon
                  v-if="lineUserId != null"
                  icon="fa-solid fa-chevron-right"
                  size="1x"
                  class="on"
                />
              </li>
              <li
                @click="
                  showMsgOk(
                    '왓츠앱',
                    'https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Whatsapp_app_green_logo.png'
                  )
                "
              >
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Whatsapp_app_green_logo.png"
                    alt="왓츠앱"/></span
                ><span>왓츠앱</span><span class="connectStatus"></span>
              </li>
              <li
                @click="
                  showMsgOk(
                    '페이스북 메신저',
                    'https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Facebook_app_gradient_logo.png'
                  )
                "
              >
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Facebook_app_gradient_logo.png"
                    alt="페이스북 메신저"/></span
                ><span>페이스북 메신저</span><span class="connectStatus"></span>
              </li>
              <li
                @click="
                  showMsgOk(
                    '텔레그램',
                    'https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Telegram_app_color_logo.png'
                  )
                "
              >
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Telegram_app_color_logo.png"
                    alt="텔레그램"/></span
                ><span>텔레그램</span><span class="connectStatus"></span>
              </li>
              <li
                @click="
                  showMsgOk(
                    '인스타그램',
                    'https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Instagram_app_gradient_logo.png'
                  )
                "
              >
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Instagram_app_gradient_logo.png"
                    alt="인스타그램"/></span
                ><span>인스타그램</span><span class="connectStatus"></span>
              </li>
              <li
                @click="
                  showMsgOk(
                    '트위터',
                    'https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Twitter_app_blue_logo.png'
                  )
                "
              >
                <span
                  ><img
                    src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Twitter_app_blue_logo.png"
                    alt="트위터"/></span
                ><span>트위터</span><span class="connectStatus"></span>
              </li>
              <!-- <li class="add" @click="onClick()"><span>+ 데이터 추가</span></li> -->
            </ul>
          </li>
          <li class="edu">
            <h3>
              <font-awesome-icon
                icon="fa-solid fa-graduation-cap"
                size="1x"
              /><span>교육</span>
            </h3>
            <ul class="apps">
              <li><span>준비중</span><span>DYB어학원</span></li>
              <li><span>준비중</span><span>해커스어학원</span></li>
              <li><span>준비중</span><span>YBM어학원</span></li>
            </ul>
          </li>
          <li class="ott">
            <h3>
              <font-awesome-icon icon="fa-solid fa-tv" size="1x" /><span
                >OTT</span
              >
            </h3>
            <ul class="apps">
              <li><span>준비중</span><span>넷플릭스</span></li>
              <li><span>준비중</span><span>디즈니 플러스</span></li>
              <li><span>준비중</span><span>HBO</span></li>
              <li><span>준비중</span><span>왓챠</span></li>
              <li><span>준비중</span><span>트위치</span></li>
            </ul>
          </li>
          <li class="bank">
            <h3>
              <font-awesome-icon icon="fa-solid fa-piggy-bank" size="1x" /><span
                >금융</span
              >
            </h3>
            <ul class="apps">
              <li><span>준비중</span><span>국민은행</span></li>
              <li><span>준비중</span><span>신한은행</span></li>
              <li><span>준비중</span><span>Wells Fargo</span></li>
              <li><span>준비중</span><span>American Express</span></li>
            </ul>
          </li>
          <li class="public">
            <h3>
              <font-awesome-icon icon="fa-solid fa-id-card" size="1x" /><span
                >공공기록</span
              >
            </h3>
            <ul class="apps">
              <li><span>준비중</span><span>운전면허증</span></li>
              <li><span>준비중</span><span>국민연금</span></li>
              <li><span>준비중</span><span>여권</span></li>
              <li><span>준비중</span><span>고용보험</span></li>
            </ul>
          </li>
          <li class="health">
            <h3>
              <font-awesome-icon
                icon="fa-solid fa-heart-pulse"
                size="1x"
              /><span>건강</span>
            </h3>
            <ul class="apps">
              <li><span>준비중</span><span>진료기록</span></li>
              <li><span>준비중</span><span>처방전기록</span></li>
              <li><span>준비중</span><span>병원예약</span></li>
              <li><span>준비중</span><span>건강보험</span></li>
            </ul>
          </li>
        </ul>
        <!-- <div class="popup">
        <div class="popwin">
          <h4>추가할 앱 선택</h4>
          <ul class="options">
            <li>텔레그램</li>
            <li>인스타그램 DM</li>
            <li>라인</li>
            <li>iMessage</li>
            <li>디스코드</li>
            <li>...</li>
          </ul>
          <p @click="onClick()">닫기</p>
        </div>
      </div> -->
        <!-- <div class="dataselect">
        <div class="selectwin">
          <div class="title-box">
            <a href="#" @click="onDetail()"
              ><font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"
            /></a>
            <h2>데이터 관리</h2>
            <b-button class="sharebtn" @click="onShare()"
              ><font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket"
            /></b-button>
          </div>
          <table>
            <colgroup>
              <col class="w1" />
              <col class="w2" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="col">서비스 이름</th>
                <td>카카오톡</td>
              </tr>
              <tr>
                <th scope="col">공유시작일</th>
                <td>2022.07.31</td>
              </tr>
              <tr>
                <th scope="col">총 데이터 크기</th>
                <td>17GB</td>
              </tr>
              <tr>
                <th scope="col">데이터 타입</th>
                <td>문자, 사진, 동영상</td>
              </tr>
            </tbody>
          </table>
          <div class="button-group">
            <b-button
              class="m-2"
              to="/bookmarks"
              style="background-color: #5ebdeb;border: none;outline: none;"
              ><font-awesome-icon
                icon="fa-solid fa-file-arrow-down"
                size="1x"
                class="pr-1"
              />다운로드</b-button
            >
          </div>
        </div>
      </div>
      <div class="datashare">
        <div class="bg" @click="onDone()"></div>
        <div class="share">
          <h4>데이터 공유하기</h4>
          <p>데이터를 공유하면 리워드를 받을 수 있어요!</p>
          <div class="title-btn">
            <h5>
              주식회사 사각
              <p
                style="margin:0; text-align: left;font-size: 14px;color: #FB5C5C;margin-top: 2px;"
              >
                500 포인트
              </p>
            </h5>
            <button class="" @click="onDone()">공유</button>
          </div>
          <div class="share-detail">
            <h6>데이터 보유 기간</h6>
            <p>2023.02.20 ~ 2024.02.19 <span>(365일)</span></p>
            <h6>목적</h6>
            <p>캔디 사용자 통계 분석용</p>
          </div>

          <h6 style="text-align: left;font-weight: 600;">거래 데이터</h6>
          <ul>
            <li @click="onToggle(1)">
              <span>텍스트 파일</span>
              <p><span></span></p>
            </li>
            <li @click="onToggle(2)">
              <span>미디어 파일</span>
              <p><span></span></p>
            </li>
            <li @click="onToggle(3)">
              <span>기타 파일</span>
              <p><span></span></p>
            </li>
          </ul>
        </div>
      </div> -->
      </div>
    </div>
    <div v-else>
      <SolidLoginButton />
    </div>
  </div>
</template>

<script>
import loginMixin from "@/mixins/loginMixin";

export default {
  name: "Editor",
  components: {
    //'Browser': () => import('@/components/explorer/Browser'),
    //'Explorer': () => import('@/components/explorer/Explorer'),
    SolidLoginButton: () => import("@/components/solid/SolidLoginButton"),
  },
  mixins: [loginMixin],
  data: function() {
    return {
      //  storage: "",
      //folder: {}
    };
  },
  created() {
    if (this.webId == null) {
      this.popupLogin();
    }

    if (localStorage.getItem("kakao_access_token") != null) {
      const token = localStorage.getItem("kakao_access_token")
      //console.log(token);
      this.$store.dispatch('kakao/getKakaoUser', token);
    }
    
    if (localStorage.getItem("access_token") != null) {
      const token = localStorage.getItem("access_token")
      this.$store.dispatch('line/getLineUser', token);
    }
    //  this.solid= window.solid
    //  this.webId =
    //  fc = new SolidFileClient(auth)
    //  example     await solid.data.from(this.fileUrl)[index]['http://www.w3.org/2005/01/wf/flow#message'].set(namedNode(messUri))
  },
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
    storage() {
      return this.$store.state.solid.storage;
    },
    folder() {
      return this.$store.state.solid.folder;
    },
    kakaoUserId() {
      return this.$store.state.kakao.kakaoUserId;
    },
    lineUserId() {
      return this.$store.state.line.lineUserId;
    },
  },
  methods: {
    onClick() {
      let popup = document.querySelector(".popup");
      popup.classList.toggle("on");
    },
    open_inbox(service) {
      if (this.$route.path !== `/${service}`) {
        this.$router.push({ path: `/${service}` });
      }
    },
    showMsgOk(name, id) {
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h("div", {
        domProps: { innerHTML: `<b>${name}<b> 로그인` },
      });
      // More complex structure
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("b-img", {
          props: {
            src: `${id}`,
            center: true,
            fluid: true,
          },
        }),
        h("p", { class: ["welcome-msg"] }, [`${name} 연결하기`]),
        h("div", { class: ["login-form"] }, [
          h("div", { class: ["login-id"] }, [
            h("label", { class: ["id-label"] }, ["ID : "]),
            h("input", { class: ["id-input"] }, []),
          ]),
          h("div", { class: ["login-pw"] }, [
            h("label", { class: ["pw-label"] }, ["PW : "]),
            h("input", { class: ["pw-input"] }),
          ]),
        ]),
        h("div", { class: ["login-button"] }, [
          h("button", { class: ["loginbtn"] }, [" 로그인 "]),
        ]),
      ]);
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        centered: true,
        size: "md",
        footerClass: "d-none",
        cancelTitle: "NO",
        hideHeaderClose: false,
      });
    },
    // onDetail() {
    //   let dataselect = document.querySelector(".dataselect");
    //   let box = document.querySelector(".box");
    //   if (!dataselect.classList.contains("on")) {
    //     dataselect.classList.add("on");
    //     box.style.height = `${dataselect.clientHeight}px`;
    //     box.style.overflow = "hidden";
    //   } else {
    //     dataselect.classList.remove("on");
    //     box.style.height = "fit-content";
    //     box.style.overflow = "auto";
    //   }
    // },
    // onShare() {
    //   let datashare = document.querySelector(".datashare");
    //   datashare.classList.add("on");
    // },
    // onDone() {
    //   let datashare = document.querySelector(".datashare");
    //   datashare.classList.remove("on");
    // },
    // onToggle(idx) {
    //   let toggle = document.querySelector(
    //     `.datashare .share ul li:nth-child(${idx}) p`
    //   );
    //   toggle.classList.toggle("on");
    // },
  },
};
</script>

<style scoped>
/* 공통 */
.box {
  position: relative;
  width: 100%;
  margin: auto;
  background: #fcfcfc;
  height: fit-content;
  padding: 0 5px;
}
.title-box {
  width: 93%;
  margin: auto;
  text-align: left;
  padding: 0 5px;
}
.title-box a {
  display: block;
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
}
/* 공유버튼 */
.title-box .sharebtn {
  margin: 0;
  background: none;
  border: none;
  padding: 0 2.7778vw;
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
}
.title-box .sharebtn svg {
  color: #333;
}
.title-box .sharebtn:active,
.title-box .sharebtn.active {
  background-color: #fff;
  border-color: none;
}
.title {
  background: #fcfcfc;
  margin: 20px auto 0;
  box-sizing: border-box;
  text-align: left;
  width: 90%;
}
.title h2 {
  font-weight: 900;
  line-height: 1.3;
  margin: 0;
  font-size: 24px;
}
.title p {
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  word-break: keep-all;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

h3 {
  padding: 10px 0;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
}

h3 > span {
  display: block;
  margin-left: 10px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
}
.category {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 0;
}
.category > li {
  width: 20rem;
  margin: 20px;
  border-radius: 10px;
  padding: 0;
  padding-bottom: 10px;
}
.apps {
  width: 100%;
  padding: 0;
}
.apps > li {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 0;
}
.apps > li:last-child {
  border: none;
}
.apps > li > span {
  display: block;
}
.apps > li > span:first-child {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: linear-gradient(
    45deg,
    #0a7cff 0%,
    #a10eeb 60%,
    #ff5297 93%,
    #ff6c5c 100%,
    #7db9e8 100%
  );
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  margin-right: 15px;
  text-align: center;
  font-weight: 600;
}
.sns > .apps > li > span:first-child {
  /* 아이콘 */
  width: 35px;
  height: 35px;
  margin-right: 15px;
  overflow: hidden;
  position: relative;
  background: transparent;
}
.apps > li > span > img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.apps > li > span.connectStatus {
  width: 50px;
  height: 20px;
  line-height: 20px;
  background: limegreen;
  border-radius: 10px;
  position: absolute;
  right: 30px;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
  display: none;
}
.apps > li > span.connectStatus.on {
  display: block;
}
.apps > li > svg {
  position: absolute;
  right: 0px;
  color: #999;
  display: none;
}
.apps > li > svg.on {
  display: inline-block;
}

.apps > li.add {
  width: 15rem;
  background: none;
  color: cadetblue;
  font-weight: bold;
  cursor: pointer;
}
.apps > li.add:hover,
.apps > li.add:focus {
  color: darkblue;
}

.foobar {
  width: 100%;
  margin: auto;
}
.foobar img {
  width: 100px;
  height: 100px;
  margin: 30px 0 15px;
}
.foobar .welcome-msg {
  text-align: center;
  font-size: 20px;
  margin: 40px 0 20px;
  font-weight: 600;
}
.foobar .login-form {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}
.foobar .login-form .login-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 70%;
}
.foobar .login-form .login-pw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 70%;
}
.foobar .login-form label {
  margin: 0;
  text-align: right;
  width: 20%;
}
.foobar .login-form input {
  outline: 1px solid #999;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  padding: 2px 5px;
  width: 65%;
}

.foobar .login-button {
  text-align: center;
  padding-bottom: 30px;
}
.loginbtn {
  outline: none;
  border: none;
  margin: auto;
  width: 80%;
  padding: 10px 0;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
}

/* 서비스 추가하기
.popup {
  display: none;
}
.popup.on {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
.popup .popwin {
  width: 20rem;
  height: 30rem;
  background: #fff;
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  padding: 20px;
}
.popup .popwin h4 {
  margin-bottom: 30px;
}
.popup .popwin .options {
}
.popup .popwin .options li {
  width: 70%;
  background: lightblue;
  margin: auto;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  padding: 7px;
  border-radius: 20px;
}
.popup .popwin .options li:nth-child(5) {
  margin-bottom: 0;
}
.popup .popwin .options li:last-child {
  background-color: transparent;
  height: 2px;
  margin-bottom: 20px;
  margin-top: -5px;
}
.popup .popwin p {
  font-weight: 600;
  color: #fff;
  margin: auto;
  margin-top: 30px;
  padding: 5px;
  background-color: rgba(255, 0, 0, 0.5);
  width: 30%;
  border-radius: 10px;

  .dataselect {
  position: absolute;
  top: 0;
  left: 100%;
  padding: 0 5px;
  transition: 0.5s;
  background: #fcfcfc;
  width: 100%;
  min-height: 550px;
  height: fit-content;
  display: none;
}
.dataselect.on {
  left: 0;
  display: block;
}

.dataselect .title-box {
  margin-bottom: 30px;
}
.dataselect > h4 {
  margin-bottom: 20px;
  font-weight: 600;
}
.dataselect table {
  text-align: left;
  margin: auto;
  width: 80%;
  margin-bottom: 20px;
}
.dataselect table tr {
  border: 1px solid #999;
}
.dataselect table th {
  border-right: 1px solid #999;
  padding: 10px;
  background: #efefef;
  font-size: 14px;
}
.dataselect table td {
  padding: 10px;
  font-size: 14px;
}
.dataselect table .w1 {
  width: 40%;
}
.dataselect > li {
  margin-bottom: 10px;
}
.dataselect .button-group {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 70%;
}
.dataselect .button-group a {
  font-size: 15px;
  width: 120px;
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
}
.dataselect .button-group button {
  font-size: 15px;
  width: 120px;
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
}
.dataselect svg {
  margin-right: 5px;
}
.dataselect p.close-popup {
  margin: auto;
  margin-top: 20px;
  font-weight: 600;
  color: #fff;
  width: 65%;
  background: #999;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
}

공유하기

.datashare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  display: none;
}
.datashare.on {
  display: block;
  top: 0;
  transition: 0.5s;
  opacity: 1;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.3);
}
.datashare .bg {
  background: transparent;
  width: 100%;
  height: 100%;
}
.datashare .share {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: #fff;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  padding: 30px 30px 40px;
}
.datashare .share h4 {
  font-weight: 600;
  font-size: 18px;
}
.datashare .share > p {
  line-height: 1.2;
  font-size: 15px;
  margin: 0;
  word-break: keep-all;
}
.datashare .share .title-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #999;
  padding: 5px 0;
  margin: 20px 0 20px;
}
.datashare .share .title-btn h5 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
}
.datashare .share .title-btn button {
  margin: 0;
  padding: 3px 0;
  border: none;
  outline: none;
  width: 60px;
  height: fit-content;
  border-radius: 5px;
  background-color: #5ebdeb;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.datashare .share ul {
  margin: 0;
  padding: 0;
  margin-top: 5px;
}
.datashare .share ul li {
  margin: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 10px;
  border: none;
  background: #efefef;
  border-radius: 5px;
}
.datashare .share ul li > span {
  display: block;
  text-align: left;
  font-size: 14px;
}
.datashare .share ul li p {
  display: block;
  width: 40px;
  background: #999;
  border-radius: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-top: 15px;
  position: relative;
}
.datashare .share ul li p span {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 1px;
}
.datashare .share ul li p.on {
  background: #00c853;
}
.datashare .share ul li p.on span {
  left: auto;
  right: 1px;
}
.share-detail {
  text-align: left;
  margin-bottom: 10px;
}
h6 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 0;
}
.share-detail p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}
.share-detail p span {
  color: #fb5c5c;
}
*/
</style>
